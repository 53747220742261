@import '../../../../../style/configs/color.css';
.container-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1.5px solid $gris-dark;
    background-color: #F5F5F5;
    color: #5A5A5A;
}
.container-item:first-child {
    padding-top: 0 !important
}
.container-bckg-orange {
    /* background-color: #FF9A64 !important; */
    color: $negro-dark2;
    background: linear-gradient(to right, $primary, $primary);
}
.Select-menu-outer{
    z-index: 1000 !important;
}
.container-total {
    padding: 0 20px;
}
.container-botones-venta {
    width: 100%;
    padding: 10px 10px !important;
    background-color: #FFF;
}
.backgr-white {
    background-color: #FFF !important;
}
.content-total {
    text-align: right;
}
.venta_vale {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline !important;
    margin-top: 8px;
}

.btn-delete {
    cursor: pointer;
    background-color: transparent;
    color: #ab000d;
    border: 0;
}
.btn-delete:hover {
    background-color: #f3e5f5;
}

.not-display {
    display: none;
}
.venta-btns{
    overflow: hidden;
    height: auto;
    max-height: 100px;
    -webkit-transition-property: max-height, padding;
    -webkit-transition-duration: 0.4s, 0.4s;
    -moz-transition-property: max-height, padding;
    -moz-transition-duration: 0.4s, 0.4s;
    -ms-transition-property: max-height, padding;
    -ms-transition-duration: 0.4s, 0.4s;
    -o-transition-property: max-height, padding;
    -o-transition-duration: 0.4s, 0.4s;
    transition-property: max-height, padding;
    transition-duration: 0.4s, 0.4s;
}
.venta-btns.collapsed{
    max-height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.detalle-item {
    overflow: hidden;
    height: auto;
    max-height: 120px;
    -webkit-transition-property: max-height, padding;
    -webkit-transition-duration: 0.4s, 0.4s;
    -moz-transition-property: max-height, padding;
    -moz-transition-duration: 0.4s, 0.4s;
    -ms-transition-property: max-height, padding;
    -ms-transition-duration: 0.4s, 0.4s;
    -o-transition-property: max-height, padding;
    -o-transition-duration: 0.4s, 0.4s;
    transition-property: max-height, padding;
    transition-duration: 0.4s, 0.4s;
}
.detalle-item.collapsed {
    max-height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.container-item-collapsed {
    border-left: 4px solid $negro-dark2;
}
.icono-flechas {
    color: $gris-dark;
    font-size: 20px !important;
    font-weight: bold !important;
}

.precio-anterior {
    text-decoration: line-through;
    font-size: 10px;
}

.cobro-container {
    border-bottom: 0 !important;
    border-bottom-left-radius: 0  !important;
    border-bottom-right-radius: 0 !important;
}

.cobro-footer {
    border-top: 0 !important;
    border-top-left-radius: 0  !important;
    border-top-right-radius: 0 !important;
}

.venta-cantidad {
    border-radius: 0 !important;
    border-color: $primary !important;
}
.btn-item-venta {
    background-color: $primary;
    padding: 0.375rem !important;
    height: 100%;
    img{
        margin: 0 !important;
    }
}
.btn-azul-venta {
    background-color: $secondary;
    padding: 0.375rem !important;
    img{
        margin: 0 !important;
    }
}
.btn-menos{
    border-radius: 1.1em 0 0 1.1em !important;
}
.btn-mas{
    border-radius: 0 1.1em 1.1em 0 !important;
}

.img-icono-cantidades {
    width: 12px;
    height: 12px;
}

.container-btn-pagar {
    padding: 0 5px;
    height: 77px;
    .btn-primary {
        border-radius: 10px;
        font-size: 20px;
        img {
            width: 25px;
            height: 25px;
            max-width: 50px;
            margin-right: 10px;
        }
    }
}

.container-btns-derecha {
    padding: 0 5px 0 10px;
    height: 77px;
    .btn-secondary, .btn-azul-oscuro {
        border-radius: 5px;
        img {
            margin-right: 10px;
        }
    }
}

.btn-venta {
    height: 71px;
    width:72px;
    img {
        margin: 0 0 0.25rem 0 !important;
    }
}
.btn-cotizacion {
    height: 40px;
    width: 40px;
    -webkit-transition: width 0.5s, height 0.5s !important;
    transition: width 0.5s, height 0.5s !important;
    img {
        margin: 0 !important;
    }
    span {
        display: none;
    }
}

.btn-cotizacion:hover {
    height: 71px;
    width:72px;
    img {
        margin: 0 0 0.25rem 0 !important;
    }
    span {
        display: block;
        -webkit-animation: fadeIn 0.7s !important;
        animation: fadeIn 0.7s !important;
    }
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.btn-agregar-venta {
    background-color: $blanco;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 3px solid $primary !important;

}

@media only screen and (max-width: 768px) {
    .content-total {
        text-align: left;
    }

    .container-botones-venta {
        max-height: fit-content;
        min-height: 87px;
        padding: 5px 5px !important;
        .btn {
            margin: 10px 0px !important;
        }
    }

    .btn-venta {
        font-size: 10px !important;
        width: 59px !important;
    }

    .container-btn-pagar {
        padding: 0 5px;
        height: auto;
        width: 100%;
        .btn-primary {
            margin: 10px 0 0 0 !important;
        }
    }

    .container-btns-derecha {
        padding: 0 5px;
        height: auto;
        width: 100%;
        .btn-secondary {
            margin: 0 0 10px 0 !important
        }
    }
}

@media only screen and (max-width: 991px) {
    .venta-mt-4 {
        margin-top: 1.5rem !important;
    }
}
