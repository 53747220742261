.contenedor {
    position: fixed;
    right: 0px;
    bottom: 50vh;
    width: 42px;
    height: 42px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    overflow: hidden;
    box-shadow: #cacab7 0px 0px 10px 1px;
    transition: width 0.5s 0s;
    .ayuda {
        cursor: pointer;
        color: #4D4D4D;
        font-size: 12px;
        font-weight: bold;
        display: none;
        opacity: 0;
        transform: translateX(300px);
        transition: display 0s, opacity 0.5s 0.1s, transform 0.5s;
        width: 250px;
        text-align: right;
        background-color: #FFFFFF;
        padding: 3px 10px;
        border-bottom: solid 1px rgba(194, 194, 195, 0.5);
        &:hover {
            background-color: #E6E6E6;
        }
    }
    &:hover {
        height: auto;
        width: 250px;
        .ayuda {
            display: block;
            opacity: 1;
            transform: translateX(0);
        }
        .floating-button {
            background-color: #2C78B8;
            width: 100%;
            .etiqueta {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}

.contenedor-no-desplegable {
    position: fixed;
    right: 0px;
    bottom: 50vh;
    width: 42px;
    height: 42px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    overflow: hidden;
    box-shadow: #cacab7 0px 0px 10px 1px;
    cursor: pointer;
}
.floating-button {
    cursor: pointer;
    height: 42px;
    width: 42px;
    text-align: right;
    background-color: #256FAC;
    overflow: hidden;
    color: #FFF;
    transition: width 0.5s, background-color 0.5s;
    white-space: nowrap;
    text-indent: 5px;
    .etiqueta {
        font-weight: bold;
        display: inline-block;
        transform: translateX(300px);
        opacity: 0;
        transition: opacity 0.3s 0.3s, transform 0.3s 0.3s;
    }
}

.icono-ayuda {
    height: 42px;
    width: 42px;
    background-color: #256FAC;
}

