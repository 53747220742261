.container-img-configuracion {
    background-color: #7F7F7F;
    position        : relative;
    width           : 100%;
    padding         : 25px;
    height          : 222px;
    display         : flex;
    align-items     : center;
    justify-content : center;
}

.container-img-configuracion .img-configuracion {
    max-width  : 100%;
    max-height : 100%;
    margin     : auto;
    position   : absolute;
    top        : 0;
    bottom     : 0;
    left       : 0;
    right      : 0;
    will-change: transform;
}

.container-options {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 5px 5px 5px;

}

.bnt-imagen-configuracion {
    padding         : 2px;
    z-index         : 1;
    outline         : 1px dashed #ccc;
    background-color: transparent;
    cursor          : pointer;
    width: 40px;
}

.bnt-imagen-configuracion img {
    width : 100%;
    cursor: pointer;
}