.catalogo-producto {
    padding: 8px;
    max-height: 200px;
    overflow-y: auto;
}

@media only screen and (max-width:991px) {
    .catalogo-producto {
        padding: 0;
        max-height: unset;
        overflow-y: unset;
        margin-top: 1rem;
    }
}
