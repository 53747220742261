@import "../../../../style/configs/color.css";
.grid-clientes-ov {
    .react-bs-table-container {
        .table-bordered {
            min-width: 400px;
        }
    }
}

.contenedor-lectura-productos-ov {
    border-radius: 10px;
    display: block;
    overflow: hidden;
    margin-top: 1em;
    * > .table-bordered {
        min-width: auto !important;
    }
}
.encabezado-lista-productos-ov {
    background-color: $primary !important;
    color: $blanco;
    padding: 10px 15px;
}
.footer-lista-productos-ov {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary !important;
    color: $negro-dark2;
    padding: 10px 15px;
}

/* PASO CONFIRMACION */

.form-confirmacion {
    border: 1px solid #cdcdcd;
    border-radius: 15px;
    padding: 15px;
}

.container-impresion-ov {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* DETALLE OV */

#DETALLE-OV .encabezado {
    display: flex;
}

#DETALLE-OV .encabezado .item {
    background-color: #eff3f6;
    border-radius: 15px;
    margin: 0 7.5px;
    padding: 15px;
    flex: 1;
    text-align: right;
    color: #1d8424;
    font-size: 15px !important;
}

/* INFO OV */
#DETALLE-OV .info-ov {
    padding: 0 1cm;
    display: flex;
    flex-direction: column;
}

#DETALLE-OV .info-ov .info-ov-item {
    flex: 1;
    padding: 0 7.5px;
}

#DETALLE-OV .info-ov .info-ov-item .title {
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    border-bottom: 3px solid #ededed;
}
#DETALLE-OV .info-ov .info-ov-item .title-h-45 {
    height: 45px !important;
}

#DETALLE-OV .info-ov .info-ov-item .info-ov-item-body {
    padding: 7.5px 7.5px 0 7.5px;
    display: flex;
    justify-content: space-between;
}
#DETALLE-OV .info-ov .info-ov-item .info-ov-item-body .item {
    flex: 1;
    p {
        margin-bottom: 5px;
        font-size: 12px;
    }
    b {
        font-size: 12px;
    }
    span {
        font-size: 12px;
    }
}

.grid-end-seccion-ov {
    border-bottom: 2px solid #cdcdcd;
}
.grid-titulo-ov {
    font-size: 1.3rem;
    font-weight: bold;
    border-bottom: 3px solid #cdcdcd;
    color: #515151;
    padding: 15px;
}


.encabezado-principal{
    background: #eee;
    box-sizing: border-box;
}

.encabezado-item-datos{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center !important;
}

#IMPRESION-HOJA-OV .line {
    border-bottom: 2px solid black;
    margin-left:38px !important;
    margin-right: 38px !important;
    font-size: 11px !important;
    margin-top: -15px !important;
}


#IMPRESION-HOJA-OV .info-footer {
    margin-left:38px !important;
    margin-right: 38px !important;
    font-size: 11px !important;
}

#IMPRESION-HOJA-OV.footer {
    display: flex;
}

#IMPRESION-HOJA-OV .total-ov{
    font-size: 12px !important;
}

/*#IMPRESION-HOJA-OV .line {
    font-weight: bold;
    font-size: 10px !important;
    text-transform: uppercase;
    border-bottom: 3px solid #bb1818;
}*/


