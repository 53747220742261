@import "../../../../../../style/configs/color.css";

.encabezado-cierre {
    width: 5cm;
    font-size: 12px;
    border-bottom: 1px solid $gris-dark;
}
.titulo-cierre {
    font-size: 20px;
}
.resumen-cierre {
    border-bottom: 1px solid $gris-dark;
    font-size: 12px;
    padding-bottom: 1em;
}
.detalle-movimiento-cierre {
    margin-left: 1em;
    margin-right: 1em;
}

.cierre-elemento {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}
.cierre-subrayo {
    border-bottom: 1px dotted black;
    flex: 2;
    margin-bottom: 8px;
}
