$number-of-steps: 4;
$line-width: 2px;
$bullet-size: 25px;

#wizardstep {
    background-color: transparent;
    position: relative;
    overflow: hidden;
    counter-reset: wizard;
    padding: 0;
    li {
        position: relative;
        float: left;
        width: calc(100% / var(--steps));
        text-align: center;
        color: #000;
        list-style-type: none;
        font-weight: bold;
    }

    li:before {
        content: "";
        display: block;
        background-color: #fff;
        border: $line-width solid #000;
        text-align: center;
        width: $bullet-size;
        height: $bullet-size;
        line-height: $bullet-size;
        border-radius: $bullet-size;
        position: relative;
        left: 50%;
        margin-bottom: calc($bullet-size / 2);
        margin-left: calc($bullet-size * -0.5);
        z-index: 1;
        box-shadow: 0px 7px 8px #64646473;
    }
    li.active {
        color: #3a86ff;
    }
    li.active:before {
        font-family: FontAwesome;
        content: "\f111";
        background: #fff;
        color: #136AC6;
        font-size: 15px;
        line-height: 22px;
    }
    li.completed {
        color: #000;
    }
    li.completed:before {
        font-family: FontAwesome;
        content: "\f00c";
        background: #000;
        color: #fff;
        font-size: 15px;
        line-height: 22px;
    }

    li + li {
        &:after {
            content: "";
            display: block;
            width: 100%;
            background-color: #000;
            height: $line-width;
            position: absolute;
            left: -50%;
            top: calc($bullet-size / 2);
            z-index: 0;
        }
    }
}
