.modal-lg {
    /* max-width: 90vh; */
    min-width: auto;
    /* min-height: 70vh; */
}

.responsive-table {
    max-width: 80vw;
    overflow-y: auto;
    max-height: 350px;
}

/* .responsive-table .react-bs-table {
    position: relative;
}
.responsive-table .react-bs-container-header{
    position: fixed !important;
    top: 0;
    left: 0;
} */

@media screen and (min-width: 480px) {
    .modal-lg {
        min-width: 70vw;
    }

    .modal-lg.open {
        min-width: 40vw;
    }
}
