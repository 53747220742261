.grid-ticket {
    display: grid;
    grid-template-columns: repeat(3, minmax(60px, 1fr));
    /* grid-template-rows: 1fr 1fr 1fr; */
    gap: 5px 20px;
    padding-bottom: 10px;
}

.grid-4 {
    grid-template-columns: repeat(4, minmax(50px, 1fr));
    gap: 10px 0;
}

.grid-ticket b {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px !important;
}
