@import "../../../../style/configs/color.css";

.breadcrumb-container {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    font-style: italic;
    letter-spacing: 0.5px;
}
.breadcrumb {
    background-color:  #B1CBE7 !important;
    font-size: 18px;
    color: #888888;
    padding: 0 0 0 25px !important;
    border-radius: 0 !important;
    position: relative;
    height: 30px;
    margin-bottom: 0 !important;
}
.breadcrumb:first-child {
    padding: 0 0 0 15px !important;
    box-shadow: none !important;
}
.flecha {
    right: -8.5px;
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #B1CBE7;
    z-index: 1;
    border-width: 2px;
    border-style: solid;
    border-color: #FFF #FFF transparent transparent;
    transform: rotate(45deg);
}

.breadcrumb-activo {
    padding: 0 0 0 25px !important;
    font-size: 18px;
    color: var(--blue);
    text-transform: capitalize;
    position: relative;
    height: 30px;
    border: 2px solid var(--blue);
    border-left: 0;
    box-shadow: 4px 2px 4px rgb(0 0 0 / 25%);
}
.flecha-activa {
    right: -10.5px;
    top: 5px;
    position: absolute;
    height: 21px;
    width: 21px;
    background-color: $negro-dark2;
    z-index: 1;
    transform: rotate(45deg);
}

.crumb-item-activo {
    color: var(--blue);
    margin-right: 15px;
}
.crumb-item {
    color: #888888;
    text-transform: capitalize;
    margin-right: 15px
}

.crumb-item:hover{
    color: $azul;
    text-decoration: solid !important;
}
.breadcrumb:hover {
    background-color: #C4C5C9 !important;
    .flecha {
        background-color: #C4C5C9;
    }
}

@media only screen and (max-width: 768px) {
    .breadcrumb{
        font-size: 16px;
    }
}
