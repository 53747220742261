@import "../../../../style/configs/color.css";

.btn-caja:hover{
    text-decoration: underline !important;
}
.btn-caja{
    color: $azul;
    text-decoration: underline;
}

.caja-img{
    max-width: 80px;
    margin: 0;
}

.btn-caja img{
    max-width: 80px !important;
    margin: 0 !important;
}

#cajas-listado{
    color: $rojo;
}
#cajas-listado .activo{
    color: $verde;
}

#cajas-listado .activo .caja-img-inactivo {
    display: none !important;
}
#cajas-listado .caja-img-activo {
    display: none;
}
#cajas-listado .activo .caja-img-activo {
    display: inline-block;
}
#cajas-listado .activo .caja-link-inactivo {
    display: none !important;
}
#cajas-listado .caja-link-activo {
    display: none !important;
}
#cajas-listado .activo .caja-link-activo {
    display: inline-block !important;
}
.caja-titulo{
    font-size: 18px;
    color: $negro-dark2;
}
.apertura-cierre-titulo{
    color: $verde;
    font-weight: bold;
    font-size: 2em;
}
.apertura-cierre-titulo .apertura-cierre-total{
    font-size: 1.2em;
    font-weight: normal;
}
