@import '../../../../style/configs/color.css';
.content-drop-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}

.contentainer-prices-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 2rem;
}

.label-view {
    color: #606060;
    font-weight: bold;
}

.input-view {
    width: 100%;
    border-bottom: 3px solid #E8E8E8;
    padding: 0 15px 5px 15px;
}

.txt-info-action {
    font-weight: bold;
    color: #515151;
    text-align: justify;
    font-size: 14px;
}

.icon-arrow-down {
    font-size: 60px !important;
    color: #8C8C8C;
}

.ml0 {
    margin-left: 0 !important;
}

.ml15 {
    margin-left: 15px !important;
}
.ml30 {
    margin-left: 30px !important;
}
.mr30 {
    margin-right: 30px !important;
}
.mt15 {
    margin-top: 15px !important;
}

/* FRACCIONES */
.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.container-type-fraccion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.component-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.br1 {
    border-right: 1px solid #B7B7B7;
}

.bl1 {
    border-left: 1px solid #B7B7B7;
}

.producto-descripcion {
    background-color: #E8E8E8;
    padding: 1.2rem;
}

.container-custom-field-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.flex3 {
    flex: 3;
}
.flex1 {
    flex: 1;
}
.span-txt-fraccion {
    padding: 0 10px;
    text-align: center;
}
.footer-container {
    border-top: 1px solid #e9ecef;
    margin: 0 !important;
    padding: 15px 0;
    width: 100%;
}

.container-nombre-fraccion {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.nombre-fraccion {
    flex: 1;
    line-height: 50px;
}
.flex3 {
    flex: 3;
}

.linea-vertical {
    width: 5px;
    background-color: $negro-dark;
    height: 120px;
    margin: 0 25px;
}
.header-collapse {
    padding: 10px 15px;
    border-bottom: 2px solid #CBCBCB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $negro-dark2;
    font-weight: bold;
    cursor: pointer;
}
.header-collapse i {
    color: $negro-dark2;
}
.header-collapse .action-img {
    width: 18px;
}
.body-collapse {
    padding-top: 20px;
}
@media only screen and (max-width: 991px) {
    .rotar-90-deg {
        transform: rotate(90deg);
    }
}
