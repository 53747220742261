.birthday-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 350px));
    /* grid-template-rows: repeat(auto-fill, minmax(100px, 200px)); */
    gap: 25px;
    padding: 10px;
}

.birthday-img {
    width: 100px !important;
    border: #dddddd solid 1px;
    border-radius: 50%;
    height: 100px !important;
    margin-bottom: 15px;
}

.card-birthday {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 20px #dddddd;
    background-color: white;
}

.card-birthday-today {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 20px #dddddd;
    background: rgb(219, 236, 255);
    background: linear-gradient(
        0deg,
        rgba(219, 236, 255, 1) 0%,
        rgba(238, 247, 255, 1) 44%,
        rgba(255, 255, 255, 1) 100%
    );
}

.card-birthday-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 20px;
}

.bunting {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: -40px;
}

.card-birthday-title,
.birthday-img {
    z-index: 20;
}

.card-birthday-body {
    text-align: center;
}

.card-birthday-today .card-birthday-body {
    color: #bf3638;
}

.birthday-icon {
    position: absolute;
    z-index: 20;
    right: 5px;
    top: 5px;
    width: 40px;
}

@media (min-width: 768px) {
    .birthday-container {
        padding: 0;
        margin-top: 40px;
    }
}
