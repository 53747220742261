@import "../../../../style/configs/color.css";

#login{
  .form-control{
    border: 2px solid #D3D3D3;
    border-radius: 15px;
  }
  .form-control:active {
    border: 2px solid $primary;
  }
  .form-control:focus {
    border: 2px solid $primary;
  }
}
.text-muted-login{
  left: 15px !important;
  width: 20px;
}

.form-control-login {
  padding-left: 35px !important;
}

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.fondo-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff url("../../../../assets/img/background-login.png");
  background-size: 45% 110%;
  background-repeat: no-repeat;
  background-position: left center;
  z-index: 999;
  transition: opacity .65s;
  background-color: #F5F5F5;
}

.login-wrapper {
  position: absolute;
  top: 20vh;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 0;
}
.footer-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-login {
  width: 50% !important;
}
.txt-copy {
  color: #838383 !important;
  margin-top: 5px;
  text-align: center;
  font-weight: 600;
}
.contenedor-form-login {
  border-radius: 15px;
  box-shadow: 1px 3px 12px 0px #A7A7A7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  padding: 40px 0;
  background-color: $blanco;
}

.container-controls {
  width: 70%;
}
.container-img-fondo {
  display: none;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .container-img-fondo {
    display: block;
    width: 100%;
    img {
      width: 100%;      
      height: auto;
    }
  }
  .fondo-login {
    overflow-y: auto;
    background: none;
  }
  .login-wrapper {
    /* top: -7vh; */
    width: 100%;
    margin-bottom: 25px;
  }
  .contenedor-form-login {
    margin-top: 25px;
  }
  .img-login {
    width: 200px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
  .container-img-fondo {
    display: block;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .fondo-login {
    overflow-y: auto;
    background: none;
  }
  .login-wrapper {
    top: 0;
    width: 100%;
    margin-bottom: 25px;
  }
  .contenedor-form-login {
    width: 85%;
    margin-top: 25px;
  }
  .img-login {
    width: 200px;
    margin-bottom: 25px;
  }
  .container-controls {
    width: 90%;
  }
}


@media only screen and (min-width: 1024px) {
  .contenedor-form-login {
    width: 75%;
  }

  .img-login {
    width: 200px;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 1440px) {
  .contenedor-form-login {
    width: 55%;
  }
}
@media only screen and (min-width: 2560px) {
  .contenedor-form-login {
    width: 35%;
  }
}
.img-login {
  pointer-events: none;
}

