.detailProductModal {
    /*background: #b2dbbf;*/
    max-width: 85vw !important;
    width: 100% !important;
  }

  .verification-false {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #dc3545; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
 .verification-false span { 
     background:#fff; 
     padding:0 10px; 
     color: #dc3545
 }


 .verification-true {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #28a745; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 

.verification-true span { 
   background:#fff; 
   padding:0 10px; 
   color: #28a745
}