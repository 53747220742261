.item-slider {
    height: 150px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-color: #ccc !important;
}

.item-slider .item-slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 34);
}

.item-slider .item-slide {
    height: 150px;
    width: 250px;
}

.item-slider .item-slide img {
    width: 100%;
    height: auto;
    opacity: 1 !important;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
}

/* .item-slider .item-slide-track:hover {
    animation-play-state: paused;
} */

.item-slide {
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
}

/* Imagen #1 */
.item-slide:nth-child(1),
.item-slide:nth-child(18) {
    background-image: url("../../../../../assets/img/marcas/logo1.png");
}

/* Imagen #2 */
.item-slide:nth-child(2),
.item-slide:nth-child(19) {
    background-image: url("../../../../../assets/img/marcas/logo2.png");
}

/* Imagen #3*/
.item-slide:nth-child(3),
.item-slide:nth-child(20) {
    background-image: url("../../../../../assets/img/marcas/logo3.png");
}

/* Imagen #4 */
.item-slide:nth-child(4),
.item-slide:nth-child(21) {
    background-image: url("../../../../../assets/img/marcas/logo4.png");
}

/* Imagen #5 */
.item-slide:nth-child(5),
.item-slide:nth-child(22) {
    background-image: url("../../../../../assets/img/marcas/logo5.png");
}

/* Imagen #6 */
.item-slide:nth-child(6),
.item-slide:nth-child(23) {
    background-image: url("../../../../../assets/img/marcas/logo6.png");
}

/* Imagen #7 */
.item-slide:nth-child(7),
.item-slide:nth-child(24) {
    background-image: url("../../../../../assets/img/marcas/logo7.jpg");
}

/* Imagen #8 */
.item-slide:nth-child(8),
.item-slide:nth-child(25) {
    background-image: url("../../../../../assets/img/marcas/logo8.png");
}

/* Imagen #9 */
.item-slide:nth-child(9),
.item-slide:nth-child(26) {
    background-image: url("../../../../../assets/img/marcas/logo9.png");
}

/* Imagen #10 */
.item-slide:nth-child(10),
.item-slide:nth-child(27) {
    background-image: url("../../../../../assets/img/marcas/logo10.png");
}

/* Imagen #11 */
.item-slide:nth-child(11),
.item-slide:nth-child(28) {
    background-image: url("../../../../../assets/img/marcas/logo11.png");
}

/* Imagen #12 */
.item-slide:nth-child(12),
.item-slide:nth-child(29) {
    background-image: url("../../../../../assets/img/marcas/logo12.png");
}

/* Imagen #13 */
.item-slide:nth-child(13),
.item-slide:nth-child(30) {
    background-image: url("../../../../../assets/img/marcas/logo13.jpg");
}

/* Imagen #14 */
.item-slide:nth-child(14),
.item-slide:nth-child(31) {
    background-image: url("../../../../../assets/img/marcas/logo14.png");
}

/* Imagen #15 */
.item-slide:nth-child(15),
.item-slide:nth-child(32) {
    background-image: url("../../../../../assets/img/marcas/logo15.png");
}

/* Imagen #16 */
.item-slide:nth-child(16),
.item-slide:nth-child(33) {
    background-image: url("../../../../../assets/img/marcas/logo16.svg");
}

/* Imagen #17 */
.item-slide:nth-child(17),
.item-slide:nth-child(34) {
    background-image: url("../../../../../assets/img/marcas/logo17.png");
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 17));
    }
}

@media screen and (max-width: 768px) {
    .item-slide {
        margin-top: 20px;
        background-size: 50%;
        background-position: center;
    }
}
@media screen and (max-width: 500px) {
    .item-slide {
        margin-top: -30px;
        background-size: 50%;
        background-position: center;
    }
}
