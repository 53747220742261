.container-btn-agregar-productos {
    color: #435970;
    background-color: #BFE2FE;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    transition-property: background-color, color;
    transition-duration: 2s;
    transition-timing-function: ease;
    font-weight: bold;
}
.container-btn-agregar-productos:hover {
    color: #435970;
    background-color: #accfea;
}
.container-tabla-productos {
    .react-bs-table-container{
        .table-bordered{
            min-width: 400px;
        }
    }
}