@import "../../../../style/configs/color.css";
.sidebar {
    position: fixed;
    top: 0;
    width: 300px;
    bottom: 0;
    background-color: $blanco;
    padding-top: 15px;
    z-index: 500;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
    -ms-transition: width 0.4s;
    -o-transition: width 0.4s;
    transition: width 0.4s;
    .img-activo {
        display: none;
    }
    .v-menu {
        /* background: linear-gradient(to top, $orange1, $orange2); */
        background-color: transparent !important;
    }
    div[role="tablist"] {
        padding: 0;
    }
    div[role="tablist"].activo {
        background: $blanco !important;
        font-weight: normal;
        border-left: 0px solid #ececec !important;
    }
    /* div[role="tablist"]:hover {
        background: #ECECEC !important;
        border-left: 0px solid #ECECEC !important;
    } */
    .item-principal.activo {
        background: $blue1;
        font-weight: bold;
        border: none;
        color: $blanco;
        .img-activo {
            display: inline;
        }
        .img-inactivo {
            display: none;
        }
        img {
            filter: contrast(0%) brightness(10) !important;
        }
    }

    .menu-item {
        .activo {
            background: $blue1;
            font-weight: bold;
            border: none;
            color: $blanco;
            .img-activo {
                display: inline;
            }
            .img-inactivo {
                display: none;
            }
            img {
                filter: contrast(0%) brightness(10) !important;
            }
        }
    }

    .menu-item:hover {
        font-weight: bold;
    }
}

.sidebar-reduced {
    width: 100px !important;
    .v-menu {
        /* background: linear-gradient(to top, $orange1, $orange2); */
        background-color: transparent !important;
        width: 200px !important;
    }
    .v-menu.collapsed {
        width: 0 !important;
    }
    img + div {
        display: none;
    }
    i + div {
        display: none;
    }
    .imagen-sidebar {
        width: 50px;
        height: 50px;
        border: 0 solid;
        img {
            width: 35px;
        }
        .logo {
            display: none !important;
        }
        .logo-icon {
            display: block !important;
        }
    }
    .sidebar-item {
        border-left: 0 !important;
    }
    .accordion__arrow {
        display: none;
    }
    .texto-version {
        display: none;
    }
}

.sidebar img.sub-item {
    margin-right: 5px;
}
.sidebar img {
    width: 25px;
}

/* Sidebar item */
.sidebar-item {
    margin: 15px 10px !important;
    display: flex;
    gap: 10px;
    color: $negro2;
    font-size: 1rem;
    padding: 0.75rem 1.5625rem;
    border-radius: 10px;

    img {
        max-width: 20px;
        max-height: 20px;
    }
}

.imagen-sidebar {
    background: $blanco;

    img {
        width: 90%;
    }
    .logo {
        display: block !important;
    }
    .logo-icon {
        display: none !important;
    }
}

.no-border-sidebar {
    border: 0 solid transparent !important;
}

.sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: $negro;
    /* border-radius: 10px; */
}

.sidebar::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* background-color: rgba(17, 172, 253, 0.6); */
    background-color: #797979;
}
.sidebar::-webkit-scrollbar-thumb:hover {
    /* background-color: rgba(17, 172, 253, 0.6); */
    background-color: #797979;
}

.texto-version {
    color: #282828;
    cursor: pointer;
    margin-top: 15px;
    background-color: transparent;
    border: 2px solid $negro-dark2;
    border-radius: 15px;
    padding: 5px;
    font-size: 13px;
}
.texto-version:hover {
    background: $primary;
    border: 2px solid $primary;
}
