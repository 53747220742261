.gasto_fijo{
    overflow:hidden;
    /*transition:max-height 0.7s ease-out;*/
    height: auto;
    max-height: 500px;
}
.gasto_variable{
    overflow:hidden;
    /*transition:max-height 0.7s ease-out;*/
    height: auto;
    max-height: 500px;
}
.gasto_fijo.collapsed{
    max-height: 0;
}
.gasto_variable.collapsed{
    max-height: 0;
}

.formCrearGasto {
    margin-left: 100px;
    margin-top: 20px;
    
}

@media only screen and (max-width: 768px) {
    .formCrearGasto {
        margin: 0px;
    }
}
