.txt-black {
    color: #000 !important;
    font-weight: 500;
}

.container_permiso-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 100px;
}

.container_permiso {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 150px));
    justify-content: space-around;
    gap: 20px;
}

/* Animacion del collapse */
.animation-collapse > .ReactCollapse--collapse {
    transition: height 500ms;
}

/* Estilos del boton link */
.button-link {
    border: none;
    background: none;
    color: #136ac6;
    font-weight: 500;
    text-decoration: underline;
    margin-left: 10px;
}
