@import "../../../../style/configs/color.css";

.form-activo{
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.slide {
    .react-bs-table-container{
        .table-bordered{
            min-width: 400px;
        }
    }
}
.form-trasero{
    .react-bs-table-container{
        .table-bordered{
            min-width: 400px;
        }
    }
}

.btn-verde{
    background-color: $verde-oscuro;
    padding: 0.375rem !important;
    img{
        margin: 0 !important;
    }
}
.btn-cantidad-detalle {
    background-color: $negro-dark2;
    padding         : 0.375rem !important;

    img {
        margin: 0 !important;
    }
}
.btn-menos{
    border-radius: 1.1em 0 0 1.1em !important;
}
.btn-mas{
    border-radius: 0 1.1em 1.1em 0 !important;
}
.bodega-ingreso{
    border-radius: 0 !important;
    border-color: $negro-dark2 !important;
}

.btn-bodega {
    width: 186px;
}

.border-left-bodega {
    border-left: 2px solid #C3C5C7;
}
.bodega-info-adicional {
    width: 75%;
}
.contenedor-lectura-productos-oc {
    border-radius: 10px;
    display: block;
    overflow: hidden;
    /* margin-top: 1em; */
    * > .table-bordered {
        min-width: auto !important;
    }
}
.encabezado-lista-productos-oc {
    /* background-color: $primary !important; */
    color: $negro-dark;
}
.footer-lista-productos-oc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: $primary !important; */
    color: $negro-dark;
    padding: 10px 15px;
}

.contenedor-campos-oc {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.margin-x-15-neg {
    margin-left: -15px !important;
    margin-right: -15px !important;
}
.btn-finalizar {
    height: 30px;
    position: absolute !important;
    top: auto !important;
    left: auto !important;
    right: 0 !important;
}

@media only screen and (max-width: 991px) {
    .bodega-info-adicional{
        width: 100%
    }
    .btn-menos{
        display: none !important;
    }
    .btn-mas{
        display: none !important;
    }
    .bodega-ingreso{
        border-radius: 1.1em !important;
    }
    .slide-container{
        position: relative;
        width: 100vw;
    }
    .slide {
        position: absolute !important;
        left: -100vw;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        z-index: 2;
        height: calc(100vh - 75px);
        background-color: #eff3f7;
        .react-bs-table-container{
            .table-bordered{
                min-width: unset;
            }
        }
    }

    .form-activo.slide {
        transition: 0.5s;
        left: 0;
    }

    .form-trasero{
        right: 100vw;
        position: absolute !important;
        z-index: 1;
        height: calc(100vh - 75px);
        background-color: #eff3f7;
        transition: 0.5s;

        .react-bs-table-container{
            .table-bordered{
                min-width: 400px;
            }
        }
    }

    .form-trasero .table td{
        padding: 0.75rem 0 0.75rem;
    }

    .form-activo.form-trasero{
        transition: 0.5s;
        right: 0;
    }

    .border-left-bodega {
        border-left: 0px solid #C3C5C7;
    }
}