.rct-node-icon {
    color: #515151 !important;
}

.rct-node-parent .rct-title {
    font-weight: 700;
    color: #515151 !important;
}
.rct-node-leaf .rct-title{
    font-weight: 200;
    color: #515151 !important;
}

 .rct-collapse {
    color: #515151 !important;
}

.rct-node-leaf label:hover {
    background-color: rgba(0, 0, 0, 0.07);
}