@import "../../../../../src/style/configs/color.css";

.table-detalle-orden-online {
    border-collapse: collapse;
    tr th {
        width: 50%;
        border: 0 !important;
    }
    tr td {
        height: -webkit-fill-available !important;
    }
    th, td {
        border: 1px solid #DEDEDE !important;
    }
    th {
        background: #DEDEDE;
        padding: 5px 25px !important;
    }
    td {
        position: relative;
        padding: 10px 25px;
        font-size: 0.8rem;
    }
}

.border-gris{
    border: solid 1px #b0b1b4;
}

.text-finish{
    color: $verde-oscuro;
}

.text-pending {
    color: $naranja-oscuro;
}

.separador {
    border-right: 1px solid #4D4D4D;
    margin-top: .45em;
    margin-bottom: 0.75em;
}

.text-info-orden-online {
    color: #525252;
    font-size: 16px;
}