.container-productos-metodos-orden-online {
    max-height: calc(100vh - 23rem);
    /*min-height: 18rem;*/
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition-property: max-height;
    -webkit-transition-duration: 0.6s, 0.6s;
    -moz-transition-property: max-height;
    -moz-transition-duration: 0.6s, 0.6s;
    -ms-transition-property: max-height;
    -ms-transition-duration: 0.6s, 0.6s;
    -o-transition-property: max-height;
    -o-transition-duration: 0.6s, 0.6s;
    transition-property: max-height;
    transition-duration: 0.6s, 0.6s;
}

.container-productos-orden-online-collapsed {
    max-height: 0 !important;
}

.container-metodos-orden-online-collapsed {
    max-height: 0 !important;
}

.container-sections {
    background-color: #E8E8E8;
    padding: 0.25rem 15px;
    cursor: pointer;
}

.section-productos {
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
    transition: visibility 0s, opacity 0.2s linear;
}
.section-productos-collapsed {
    visibility: visible;
    opacity: 1;
    height: auto;
    padding: 0.25rem 15px;
}

.margin-left-metodos {
    border-left: 5px solid #E8E8E8;
}

.container-metodo {
    padding: 10px;
}

.container-encabezado-detalle-orden {
    background-color: #FFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
