@import "../../../../style/configs/color.css";

.v-menu{
    overflow: hidden;
    position: absolute;
    background: transparent !important;
    width: 100%;
    min-height: calc(100vh - 80px);
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
    -ms-transition: width 0.4s;
    -o-transition: width 0.4s;
    transition: width 0.4s;
}
.v-menu.collapsed{
    width: 0;
}

.v-menu-sucursal{
    border-bottom: $gris 2px solid;
    color: $naranja !important;
}
.v-opcion{
    color: $negro;
    padding: 10px 25px;
    text-decoration: none !important;
}
.v-opcion.active{
    color: $naranja;
}
.img-usuario {
    width: 40px !important;
    border: #dddddd solid 1px;
    border-radius: 50%;
    height: 40px !important;
}
