@import "../../../../../style/configs/color.css";
.tabla-resumen {
    min-width: 100% !important;
    thead {
        border-bottom: 2px solid #dee2e6;
    }
    tbody {
        tr {
            border-bottom: 1px solid #dee2e6;
        }
        tr:last-child {
            border-bottom: 0 solid #dee2e6;
        }
        tr:nth-child(even) {
            background-color: #fff !important;
        }
        tr:hover {
            background-color: #ececec !important;
        }
    }
}
.content-tabla-resumen {
    background-color: #fff;
    border: 1px solid #e8ebee;
}

.header-tabla-resumen {
    background-color: #a6c0db;
    padding: 10px 15px;
    color: #57535a;
    border-radius: 15px 15px 0px 0px;
}
.content-totales-resumen {
    padding-right: 0 !important;
}
.item-total-creditos {
    min-height: 100px;
    background-color: #fff;
    width: 100%;
}

.item-texto {
    padding: 10px;
    font-weight: bold;
    text-align: center;
    flex: 1.5;
    color: #fff;
    border-radius: 15px;
}

.card-green {
    background-color: #00a65a;
}

.card-yellow {
    background-color: #f39c12;
}

.card-red {
    background-color: #dd4b39;
}

.item-total {
    flex: 2.5;
    font-size: 25px;
    padding: 10px;
    color: $negro-dark2;
}
.item-total-cantidad {
    text-align: center;
    width: 80%;
    color: $negro-dark2;
    border-bottom: 2px solid $negro-dark2;
    margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
    .content-totales-resumen {
        padding: 0 !important;
    }
}
